import React from 'react';
import Button from './Button';
import Flowtime from '../img/flowtime.png';
import PhotoCaptionTool from '../img/photocaptiontool.png';
import { motion } from 'framer-motion';

const Projects = () => {
  return (
    <motion.div
      initial={{
        y: '100vh'
      }}
      animate={{
        y: 0
      }}
      transition={{
        type: 'spring',
        bounce: 0.2,
        duration: 0.75,
        delay: 0.1
      }}
    >
      <h2 className="text-2xl md:text-3xl mb-2">My Projects</h2>
      <div className="bg-white rounded-md shadow-md p-4 w-100">
        <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
          <div className="grid gap-4 justify-items-center lg:justify-items-auto grid-cols-1 md:grid-cols-2">
            <img className="w-48 h-48 rounded-md" src={Flowtime}/>
            <div className="text-center md:text-left">
              <div className="text-xl mb-2">FlowTime</div>
              <div className="mb-2">A simple pomodoro timer built for Android with React Native</div>
              <Button text="Google Play Store" link="https://play.google.com/store/apps/details?id=com.brendannoble.flowtime"/>
            </div>
          </div>
          <div className="grid gap-4 justify-items-center lg:justify-items-auto grid-cols-1 md:grid-cols-2">
          <img className="w-48 h-48 rounded-md" src={PhotoCaptionTool}/>
            <div className="text-center md:text-left">
              <div className="text-xl mb-2">PhotoCaptionTool</div>
              <div className="mb-2">A free photo captioning tool that proccesses photos entirely in your browser</div>
              <Button text="Visit site" link="https://photocaptiontool.com"/>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Projects;
