import React from 'react'
import Content from './components/Content'
import Footer from './components/Footer'
import Header from './components/Header'

const App = () => {
  return (
    <div className="container p-8 lg:pt-12 xl:pt-24 lg:max-w-screen-lg mx-auto">
      <Header/>
      <Content/>
      <Footer/>
    </div>
  )
}

export default App;
