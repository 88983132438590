import React from 'react';
import Avatar from '../img/avatar.jpg';
import { motion } from 'framer-motion';

const Header = () => {
  return (
    <div className="text-center mb-4 md:mb-8">
      <motion.img src={Avatar} className="rounded-full h-32 md:h-48 border-white border-8 shadow-md mx-auto mb-4 md:mb-8"
        initial={{
          opacity: 0,
          scale: 0,
          rotate: 45
        }}
        animate={{
          opacity: 1,
          scale: 1,
          rotate: 0
        }}
        transition={{
          type: 'spring',
          duration: 0.75,
          bounce: 0.5,
        }}
      />
      <motion.h1 className="text-gray-800 text-4xl md:text-6xl mb-2"
        initial={{
          x: '-100vw'
        }}
        animate={{
          x: 0
        }}
        transition={{
          type: 'spring',
          duration: 0.75,
        }}
      >
        Brendan Noble
      </motion.h1>
      <motion.h2 className="text-gray-700 text-md md:text-xl"
        initial={{
          x: '100vw'
        }}
        animate={{
          x: 0
        }}
        transition={{
          type: 'spring',
          duration: 0.75,
        }}
      >
        Photographer - Graphic Designer - Web Developer
      </motion.h2>
    </div>
  )
}

export default Header;
