import React from 'react'
import { motion } from 'framer-motion';

const Button = ({ text, link }) => {

  const button = {
    rest: { 
      backgroundColor: '#ECECEC' 
    },
    hover: {
      backgroundColor: '#1F2937',
      color: '#FFF'
    }
  }

  const chevron = {
    rest: { 
      opacity: 0, 
      color: '#ECECEC',
      x: 0,
      transition: {
        duration: 0.1
      }
    },
    hover: { 
      opacity: 1,
      color: '#1F2937',
      x: 20,
      transition: {
        type: 'spring',
        bounce: 0.5
      }
    }
  }

  return (
    <a href={link} target="_blank">
      <motion.div className="inline-block py-2 px-4 rounded-md relative"
        initial="rest"
        whileHover="hover"
        variants={button}
      >
        { text }
        <motion.div className="absolute right-0 top-2.5"
          variants={chevron}
        >
          <i class="fas fa-chevron-right"></i>
        </motion.div>
      </motion.div>
    </a>
  )
}

export default Button
