import React from 'react'
import Projects from './Projects'

const Content = () => {
  return (
    <div className="mb-8">
      <Projects/>
      {/* TODO: Create contact info */}
    </div>
  )
}

export default Content
