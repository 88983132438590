import React from 'react';
import { motion } from 'framer-motion';

const Footer = () => {

  const container = {
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.12,
        delay: 0.25
      }
    },
    hidden: { 
      opacity: 0, 
      transition: {
        when: "afterChildren"
      }
    }
  }

  const icons = {
    visible: { 
      opacity: 1,
      x: 0,
      scale: 1, 
      transition: {
        type: 'spring',
        bounce: 0.5
      }
     },
    hidden: { opacity: 0, x: -50, scale: 0 },
  }

  return (
    <motion.div className="flex justify-center"
      initial="hidden"
      animate="visible"
      variants={container}
    >
      <a href="https://instagram.com/brendan.noble" target="_blank">
      <motion.div variants={icons} whileHover={{ backgroundColor: '#f46f30', color: '#FFF' }} className="rounded-md shadow-md mr-4 py-2 px-4 md:py-4 md:px-8 bg-white">
          <i className="fab fa-2x fa-instagram"/>
        </motion.div>
      </a>
      <a href="https://www.facebook.com/Brendan.J.Noble/" target="_blank">
      <motion.div variants={icons} whileHover={{ backgroundColor: '#1877f2', color: '#FFF' }} className="rounded-md shadow-md mr-4 py-2 px-4 md:py-4 md:px-8 bg-white">
          <i className="fab fa-2x fa-facebook"/>
        </motion.div>
      </a>
      <a href="https://github.com/brendannoble" target="_blank">
        <motion.div variants={icons} whileHover={{ backgroundColor: '#171A21', color: '#FFF' }} className="rounded-md shadow-md mr-4 py-2 px-4 md:py-4 md:px-8 bg-white">
          <i className="fab fa-2x fa-github"/>
        </motion.div>
      </a>
      <a href="https://www.linkedin.com/in/brendan-noble/" target="_blank"> 
      <motion.div variants={icons} whileHover={{ backgroundColor: '#0a66c2', color: '#FFF' }} className="rounded-md shadow-md py-2 px-4 md:py-4 md:px-8 bg-white">
          <i className="fab fa-2x fa-linkedin"/>
        </motion.div>
      </a>
    </motion.div>
  )
}

export default Footer
